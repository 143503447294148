// custom typefaces
import "@fontsource/montserrat/variable.css"
import "@fontsource/merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

import './src/styles/global.css'
import { RecoilRoot } from "recoil"
import React from "react"

export const wrapRootElement = ({element,props}:any)=>{
  return <RecoilRoot {...props} >{element}</RecoilRoot>
}
